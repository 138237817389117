import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import { getAuthHeader, logged } from './security.service';
import { isArray, isObject } from '../helpers/javascript';
import { isBrowser, registerRequest } from '../helpers/browser';

const stripePromise = loadStripe(process.env.STRIPE_PK);

const redirectToCheckout = async sessionId => {
  const stripe = await stripePromise;
  // eslint-disable-next-line no-return-await
  return await stripe.redirectToCheckout({ sessionId });
};

export const plans = config =>
  new Promise(resolve => {
    const headers = (config && config.headers) || getAuthHeader();
    if (isBrowser()) {
      axios({
        url: `${process.env.API_HOST}/custom/payments/plan`,
        method: 'POST',
        headers
      })
        .then(response => {
          if (isObject(response.data) && response.data.success) {
            resolve({ ...response.data.success, userOwner: response.data.userOwner });
          } else resolve(false);
        })
        .catch(e => {
          resolve(false);
        });
    } else resolve(null);
  });

export const session = ({ price, type = 'stripe', category }) => {
  let successUrl = null;
  if (category === "video") {
    const user = logged();
    successUrl = `${window.location.origin}/processing/paid/${user._id}?redirect=${encodeURIComponent(window.location.pathname)}`;
  }
  axios({
    url: `${process.env.API_HOST}/custom/payments/session`,
    method: 'POST',
    headers: getAuthHeader(),
    data: { url: window.location.href, price, type, successUrl }
  }).then(response => {
    if (isObject(response.data) && response.data.session) {
      if (response.data.type === 'checkout') redirectToCheckout(response.data.session.id);
      else window.location.href = response.data.session.url;
    }
  });
};

export const prices = ({ category }) =>
  new Promise(resolve => {
    let result = null;
    if (isArray(result)) resolve(result);
    else {
      const cancelToken = registerRequest('products');
      axios({
        url: `${process.env.NEXT_PUBLIC_WORKERS_API}/plans/?category=${category}`,
        method: 'GET',
        cancelToken
      })
        .then(response => {
          if (isArray(response.data)) {
            const toResolve = response.data.sort((a, b) => {
              if (a.price > b.price) return 1;
              if (b.price > a.price) return -1;
              return 0;
            });
            resolve(toResolve);
          } else {
            console.error('Result is not an array');
            resolve([]);
          }
        })
        .catch(error => {
          console.error(error);
          resolve([]);
        });
    }
  });

export const appsumoPrices = () =>
  new Promise(resolve => {
    let result = null;
    if (isArray(result)) resolve(result);
    else {
      const cancelToken = registerRequest('appsumo-products');
      axios({
        url: `${process.env.API_HOST}/custom/payments/appsumoProducts`,
        method: 'GET',
        cancelToken
      })
        .then(response => {
          if (isArray(response.data)) {
            const toResolve = response.data.sort((a, b) => {
              if (a.price > b.price) return 1;
              if (b.price > a.price) return -1;
              return 0;
            });
            resolve(toResolve);
          } else resolve([]);
        })
        .catch(() => {
          resolve([]);
        });
    }
  });

export const manageCart = ({ status }) =>
  new Promise(() => {
    axios({
      method: 'POST',
      url: `${process.env.API_HOST}/custom/payments/manageCart?status=${status}`,
      headers: getAuthHeader()
    });
  });
